import { template as template_ab387d0dded94f4c928e991341528448 } from "@ember/template-compiler";
const WelcomeHeader = template_ab387d0dded94f4c928e991341528448(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
